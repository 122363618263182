<template>
  <div class="home-timbreTool">
    <div class="timbreTool-container">
      <div class="close-timbreTool" @click="closeTimbreTool">×</div>
      <div class="timbreTool-content">
        <div class="content-left">
          <img src="../assets/timbre-image/timbre_img.png" />
        </div>
        <div class="content-right">
          <div class="right-title">
            <img src="../assets/timbre-image/timbre_title.png" />
          </div>
          <div class="download-content">
            <div class="download-left">
              <div class="download-img">
                <img src="../assets/timbre-image/mac.png" />
              </div>
              <div class="download-explain">
                <div class="explain-title">Mac OS v1.0.4 (M1/M2/M3)</div>
                <div class="explain-paragraph">· AU、VST3 格式</div>
                <div class="explain-paragraph">· 支持 MacOS 11 及以上系统</div>
              </div>
            </div>
            <div class="download-right">
              <span @click="downloadFile('macM1')">点击下载</span>
            </div>
          </div>
          <div class="download-content">
            <div class="download-left">
              <div class="download-img">
                <img src="../assets/timbre-image/mac.png" />
              </div>
              <div class="download-explain">
                <div class="explain-title">Mac OS v1.0.4 (X86-64)</div>
                <div class="explain-paragraph">· AU、VST3 格式</div>
                <div class="explain-paragraph">· 支持 MacOS 11 及以上系统</div>
              </div>
            </div>
            <div class="download-right">
              <span @click="downloadFile('macX86')">点击下载</span>
            </div>
          </div>
          <div class="download-content">
            <div class="download-left">
              <div class="download-img">
                <img src="../assets/timbre-image/Windows.png" />
              </div>
              <div class="download-explain">
                <div class="explain-title">Windows v1.0.4 (x86-64)</div>
                <div class="explain-paragraph">· VST3 格式</div>
                <div class="explain-paragraph">· 支持 Windows10 及以上系统</div>
              </div>
            </div>
            <div class="download-right">
              <span @click="downloadFile('windows')">点击下载</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimbreTool",
  data() {
    return {
      scrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getScrollTop, true);
  },
  methods: {
    getScrollTop() {
      this.scrollTop = document.documentElement.scrollTop;
    },
    closeTimbreTool() {
      this.$emit("closeTimbreTool");
    },
    downloadFile(str) {
      let downloadUrlArr = [
        "https://statics.chordmate.lazycomposer.com/dm/package/VST/ChordmateSoundBridge-1.0.4_mac_arm.pkg",
        "https://statics.chordmate.lazycomposer.com/dm/package/VST/ChordmateSoundBridge-1.0.4_mac_x86.pkg",
        "https://statics.chordmate.lazycomposer.com/dm/package/VST/ChordmateSoundBridge-1.0.4_win.exe",
      ];
      let url =
        str === "macM1"
          ? downloadUrlArr[0]
          : str === "macX86"
          ? downloadUrlArr[1]
          : downloadUrlArr[2];
      console.log(str, url);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = url;
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(res.data.path);
    },
  },
};
</script>

<style lang="less" scoped>
.home-timbreTool {
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  min-width: 1200px;
  min-height: 900px;
  background: rgba(40, 40, 40, 0.92);
  position: fixed;
  left: 0;
  top: 0;
  .timbreTool-container {
    width: 950px;
    height: 540px;
    background-color: #141414;
    box-shadow: 0px 21px 20px -4px rgba(0, 0, 0, 0.37);
    border-radius: 10px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../assets/timbre-image/background.png");
    background-size: 950px 540px;
    .close-timbreTool {
      cursor: pointer;
      font-weight: 300;
      color: #fff;
      opacity: 0.8;
      font-size: 28px;
      position: absolute;
      top: -5px;
      right: 10px;
    }
    .timbreTool-content {
      margin: 108.5px 100px 87.5px;
      width: 803px;
      height: 266px;
      width: 750px;
      height: 344px;
      display: flex;
      justify-content: space-between;
      .content-left {
        margin-top: 39px;
        width: 267px;
        height: 266px;
        img {
          width: 267px;
          height: 266px;
        }
      }
      .content-right {
        width: 446px;
        height: 344px;
        .right-title {
          padding-left: 37px;
          margin-bottom: 26px;
          img {
            width: 241px;
          }
        }
        .download-content {
          margin-top: 5px;
          display: flex;
          justify-content: space-between;
          padding: 10px 32px;
          .download-left {
            display: flex;
            .download-img {
              margin-right: 22px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              img {
                width: 32px;
                height: 32px;
              }
            }
            .download-explain {
              .explain-title {
                margin-bottom: 6px;
                color: #fff;
                font-family: "PingFang SC";
                font-size: 17px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 117.647% */
                letter-spacing: -0.333px;
              }
              .explain-paragraph {
                color: rgba(255, 255, 255, 0.7);
                font-family: "PingFang SC";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 166.667% */
                letter-spacing: -0.333px;
              }
            }
          }
          .download-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            span {
              display: none;
              color: #0dc5b4;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 166.667% */
              letter-spacing: -0.333px;
            }
          }
        }
        .download-content:hover {
          background: linear-gradient(
            90deg,
            rgba(23, 39, 62, 0) 0%,
            rgba(49, 64, 142, 0.38) 8.22%,
            rgba(55, 66, 165, 0.45) 90.17%,
            rgba(17, 53, 106, 0) 100%
          );
          .download-right {
            span {
              display: block;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
